interface GameRuntime {
  id: "chocolate-doom";
  script: "longpoint-doom.js";
}

export interface Game {
  id: string;
  title: string;
  image: string;
  engine: GameRuntime;
  config: any;
}

const CHOCOLATE_DOOM_DEFAULTS = {
  window: true,
  nogui: true,
  nomusic: true,
  configdir: "/gamedata/chocolate-doom/",
};

const GAMES = {
  doom: {
    id: "doom",
    title: "Doom",
    image: "https://images.igdb.com/igdb/image/upload/t_cover_big/co1v7f.jpg",
    engine: {
      id: "chocolate-doom",
      script: "longpoint-doom.js",
    },
    config: {
      launchParams: Object.assign(
        { iwad: "/gamedata/chocolate-doom/doom.wad" },
        CHOCOLATE_DOOM_DEFAULTS
      ),
      preload: ["doom.wad", "default.cfg"],
    },
  },
  doom2: {
    id: "doom2",
    title: "Doom 2",
    image: "https://images.igdb.com/igdb/image/upload/t_cover_big/co1ybq.jpg",
    engine: {
      id: "chocolate-doom",
      script: "longpoint-doom.js",
    },
    config: {
      launchParams: Object.assign(
        { iwad: "/gamedata/chocolate-doom/doom2.wad" },
        CHOCOLATE_DOOM_DEFAULTS
      ),
      preload: ["default.cfg"],
    },
  },
};

export default function useGames(gameId?: string) {
  if (gameId) {
    return GAMES[gameId];
  }
  return Object.values(GAMES);
}
