import {
  FormEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  FileBrowser as ChonkyFileBrowser,
  FileList,
  FileToolbar,
  FileNavbar,
  FileData,
  FileContextMenu,
  ChonkyActions,
  setChonkyDefaults,
  FileArray,
  ChonkyFileActionData,
  FileHelper,
} from "chonky";
import { ChonkyIconFA } from "chonky-icon-fontawesome";
import { FsNode, DirectoryNode } from "../../../@types/emscripten";
import { Game } from "../../hooks/useGames";

const GAMEDATA = "gamedata";

interface CustomFileData extends FileData {
  parentId?: string;
  childrenIds?: string[];
  node?: FsNode;
}

interface FileMap {
  [fileId: string]: CustomFileData;
}

setChonkyDefaults({ iconComponent: ChonkyIconFA });

async function syncIdb(populate = false): Promise<void> {
  return new Promise((resolve, reject) => {
    window.Module.FS.syncfs(populate, (err: Error) => {
      if (err) return reject(err);
      resolve();
    });
  });
}

const nodeIsDir = (node: FsNode): node is DirectoryNode =>
  !(node.contents instanceof Uint8Array);

function nodeToFile(node: FsNode): CustomFileData {
  const fileData: CustomFileData = {
    id: String(node.id),
    name: node.name,
    modDate: new Date(node.timestamp),
    isDir: nodeIsDir(node),
    node,
  };

  if (nodeIsDir(node)) {
    fileData.childrenIds = Object.values(node.contents).map((node: FsNode) =>
      String(node.id)
    );
    fileData.childrenCount = fileData.childrenIds.length;
  } else {
    fileData.size = node.usedBytes;
  }

  // file system root nodes have themselves as their parent, but file browser
  // expects parent node to be undefined.
  if (node.parent.name !== "/") {
    fileData.parentId = String(node.parent.id);
  }

  return fileData;
}

function walkNodeTree(node: FsNode): FileMap {
  let fileMap: FileMap = {};

  if (nodeIsDir(node)) {
    for (const childNode of Object.values(node.contents)) {
      if (nodeIsDir(childNode)) {
        fileMap = { ...fileMap, ...walkNodeTree(childNode) };
      }
      const childId = String(childNode.id);
      fileMap[childId] = nodeToFile(childNode);
    }
  }

  const nodeId = String(node.id);
  fileMap[nodeId] = nodeToFile(node);

  return fileMap;
}

function useFileMap(node: DirectoryNode, rootNode?: DirectoryNode) {
  const baseFileMap = walkNodeTree(node);
  const rootFolderId = String(rootNode?.id ?? node.id);
  // const { baseFileMap, rootFolderId } = useMemo(
  //   () => ({
  //     baseFileMap:
  //     rootFolderId: String(node.id),
  //   }),
  //   []
  // );

  const [fileMap, setFileMap] = useState(baseFileMap);
  const [currentFolderId, setCurrentFolderId] = useState(rootFolderId);
  const folderChain = useFolderChain(fileMap, currentFolderId);

  const currentFolderIdRef = useRef(currentFolderId);
  useEffect(() => {
    currentFolderIdRef.current = currentFolderId;
  }, [currentFolderId]);

  const getPath = (file: CustomFileData): string => {
    let path = file.name;
    let parentId = file.parentId;
    while (parentId) {
      const parent = fileMap[parentId];
      path = `${parent.name}/` + path;
      parentId = parent.parentId;
    }
    // prepend the mountpoint
    path = GAMEDATA + "/" + path;
    return path;
  };

  const resetFileMap = useCallback(() => {
    setFileMap(baseFileMap);
    setCurrentFolderId(rootFolderId);
  }, [baseFileMap, rootFolderId]);

  const moveFiles = async (
    files: CustomFileData[],
    source: CustomFileData,
    destination: CustomFileData
  ) => {
    const sourcePath = getPath(source);
    const destinationPath = getPath(destination);

    for (const file of files) {
      const oldPath = sourcePath + "/" + file.name;
      const newPath = destinationPath + "/" + file.name;
      window.Module.FS.rename(oldPath, newPath);
    }

    await syncIdb();

    setFileMap((currentFileMap: FileMap) => {
      const newFileMap = { ...currentFileMap };
      const moveFileIds = files.map((file) => file.id);
      const moveFileIdSet = new Set(moveFileIds);

      const newSourceChildrenIds =
        source.childrenIds?.filter((id) => !moveFileIdSet.has(id)) ?? [];
      newFileMap[source.id] = {
        ...source,
        childrenIds: newSourceChildrenIds,
        childrenCount: newSourceChildrenIds.length,
      };

      const newDestinationChildrenIds = [
        ...(destination.childrenIds ?? []),
        ...moveFileIds,
      ];
      newFileMap[destination.id] = {
        ...destination,
        childrenIds: newDestinationChildrenIds,
        childrenCount: newDestinationChildrenIds.length,
      };

      for (const file of files) {
        newFileMap[file.id] = {
          ...file,
          parentId: destination.id,
        };
      }

      return newFileMap;
    });
  };

  const uploadFiles = async (event: FormEvent) => {
    const { files } = event.target as HTMLInputElement;
    if (files) {
      const pathChain = folderChain.map((file) => file?.name ?? "");
      const parentPath = ["gamedata", ...pathChain].join("/");

      const fileUploads = Array.from(files).map((file) => {
        return file.arrayBuffer().then((buffer) => {
          const filePath = parentPath + "/" + file.name;
          window.Module.FS.writeFile(filePath, new Uint8Array(buffer));
          return filePath;
        });
      });

      const uploadedFilePaths = await Promise.all(fileUploads);
      await syncIdb();

      setFileMap((currentFileMap) => {
        const newFileMap = { ...currentFileMap };

        const currentFolder = newFileMap[currentFolderIdRef.current];

        if (currentFolder) {
          const uploadedFileIds = [];

          // add children to file map
          for (const path of uploadedFilePaths) {
            const { node } = window.Module.FS.lookupPath(path);
            const fileData = nodeToFile(node);
            fileData.parentId = currentFolder.id;
            newFileMap[fileData.id] = fileData;
            uploadedFileIds.push(fileData.id);
          }

          // update current folder's child references
          const existingChildrenIds = currentFolder.childrenIds ?? [];
          const newChildrenIds = [...existingChildrenIds, ...uploadedFileIds];
          newFileMap[currentFolderIdRef.current] = {
            ...currentFolder,
            childrenIds: newChildrenIds,
            childrenCount: newChildrenIds.length,
          };
        }

        return newFileMap;
      });
    }
  };

  const deleteFiles = async (files: CustomFileData[]) => {
    const pathChain = folderChain.map((file) => file?.name ?? "");
    const parentPath = [GAMEDATA, ...pathChain].join("/");

    for (const file of files) {
      const filePath = parentPath + "/" + file.name;
      window.Module.FS.unlink(filePath);
    }

    await syncIdb();

    setFileMap((currentFileMap: FileMap) => {
      const newFileMap = { ...currentFileMap };

      for (const file of files) {
        delete newFileMap[file.id];

        if (file.parentId) {
          const parentFolder = newFileMap[file.parentId];
          const newChildrenIds = parentFolder.childrenIds?.filter(
            (id) => id !== file.id
          );
          newFileMap[file.parentId] = {
            ...parentFolder,
            childrenIds: newChildrenIds,
            childrenCount: newChildrenIds?.length,
          };
        }
      }

      return newFileMap;
    });
  };

  const createFolder = async (folderName: string) => {
    const currentPath = getPath(fileMap[currentFolderIdRef.current]);
    const folderPath = currentPath + "/" + folderName;
    const node = window.Module.FS.mkdir(folderPath);
    await syncIdb();

    setFileMap((currentFileMap: FileMap) => {
      const newFileMap = { ...currentFileMap };

      const newFileData = nodeToFile(node);
      newFileMap[newFileData.id] = newFileData;

      const parent = newFileMap[currentFolderIdRef.current];
      const newChildrenIds = [...(parent.childrenIds ?? []), newFileData.id];
      newFileMap[currentFolderIdRef.current] = {
        ...parent,
        childrenIds: newChildrenIds,
        childrenCount: newChildrenIds.length,
      };

      return newFileMap;
    });
  };

  return {
    fileMap,
    currentFolderId,
    setCurrentFolderId,
    resetFileMap,
    uploadFiles,
    deleteFiles,
    moveFiles,
    createFolder,
  };
}

function useFiles(fileMap: FileMap, currentFolderId: string): FileArray {
  return useMemo(() => {
    const currentFolder = fileMap[currentFolderId];
    const childrenIds = currentFolder.childrenIds;
    const files = childrenIds?.map((fileId: string) => fileMap[fileId]);
    return files ?? [];
  }, [currentFolderId, fileMap]);
}

function useFolderChain(fileMap: FileMap, currentFolderId: string): FileArray {
  return useMemo(() => {
    const folderChain: CustomFileData[] = [];

    const currentFolder = fileMap[currentFolderId];

    if (currentFolder) {
      folderChain.push(currentFolder);
      let parentId = currentFolder.parentId;
      while (parentId) {
        const parentFile = fileMap[parentId];
        if (parentFile) {
          folderChain.unshift(parentFile);
          parentId = parentFile.parentId;
        } else {
          break;
        }
      }
    }

    return folderChain;
  }, [currentFolderId, fileMap]);
}

function useFileActionHandler(
  setCurrentFolderId: (folderId: string) => void,
  deleteFiles: (files: CustomFileData[]) => void,
  moveFiles: (
    files: CustomFileData[],
    source: CustomFileData,
    destination: CustomFileData
  ) => void,
  createFolder: (folderName: string) => void
) {
  return useCallback(
    (data: ChonkyFileActionData) => {
      if (data.id === ChonkyActions.OpenFiles.id) {
        const { targetFile, files } = data.payload;
        const fileToOpen = targetFile ?? files[0];
        if (fileToOpen && FileHelper.isDirectory(fileToOpen)) {
          setCurrentFolderId(fileToOpen.id);
          return;
        }
      } else if (data.id === ChonkyActions.UploadFiles.id) {
        const inputElement = document.getElementById("file-uploader");
        inputElement?.click();
      } else if (data.id === ChonkyActions.DeleteFiles.id) {
        deleteFiles(data.state.selectedFilesForAction);
      } else if (data.id === ChonkyActions.MoveFiles.id) {
        moveFiles(
          data.payload.files,
          data.payload.source!,
          data.payload.destination
        );
      } else if (data.id === ChonkyActions.CreateFolder.id) {
        const folderName = prompt("New folder name:");
        if (folderName) {
          createFolder(folderName);
        }
      }
    },
    [setCurrentFolderId, deleteFiles, moveFiles, createFolder]
  );
}

interface FileBrowserProps {
  game: Game;
}

export default function FileBrowser({ game }: FileBrowserProps) {
  const fileActions = [
    ChonkyActions.UploadFiles,
    ChonkyActions.DeleteFiles,
    ChonkyActions.CreateFolder,
  ];

  // for now, only lookup the path for the engine that's running
  const node = window.Module.FS.lookupPath(`${GAMEDATA}/${game.engine.id}`)
    .node as DirectoryNode;
  // const gameFolderNode = node.contents[game.engine.id] as DirectoryNode;

  const {
    fileMap,
    currentFolderId,
    setCurrentFolderId,
    uploadFiles,
    deleteFiles,
    moveFiles,
    createFolder,
  } = useFileMap(node);
  const files = useFiles(fileMap, currentFolderId);
  const folderChain = useFolderChain(fileMap, currentFolderId);
  const handleFileAction = useFileActionHandler(
    setCurrentFolderId,
    deleteFiles,
    moveFiles,
    createFolder
  );

  return (
    <ChonkyFileBrowser
      files={files}
      folderChain={folderChain}
      fileActions={fileActions}
      onFileAction={handleFileAction}
      // disableDefaultFileActions={}
    >
      <input
        id="file-uploader"
        type="file"
        multiple
        style={{ visibility: "hidden" }}
        onChange={uploadFiles}
      />
      <FileNavbar />
      <FileToolbar />
      <FileList />
      <FileContextMenu />
    </ChonkyFileBrowser>
  );
}
