import { Link } from "react-router-dom";
import useGames from "../../hooks/useGames";
import "./GameLibrary.css";

export default function GameSelect() {
  const games = useGames();

  const cards = games.map((game) => (
    <Link to={game.id} key={game.id}>
      <div className="game-card">
        <img src={game.image} alt={game.title} />
      </div>
    </Link>
  ));
  return <div className="container">{cards}</div>;
}
