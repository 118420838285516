import { useEffect } from "react";
import { EmscriptenModule } from "../../../@types/emscripten";
import useGames, { Game } from "../../hooks/useGames";
import "./GameView.css";

function mountEngine(game: Game) {
  const gameDataPath = `/gamedata/${game.engine.id}/`;

  window.Module = {
    onRuntimeInitialized: () => {
      // build launch arguments
      const paramEntries = Object.entries(game.config.launchParams);
      const paramArgs: any[] = [];
      for (const [name, value] of paramEntries) {
        if (!!value) {
          paramArgs.push(`-${name}`);
          if (typeof value !== "boolean") {
            paramArgs.push(value);
          }
        }
      }

      // sync with persistent storage (IDBFS)
      window.Module.FS.syncfs(true, function (err) {
        const gameDataExists =
          window.Module.FS.analyzePath(gameDataPath).exists;
        if (!gameDataExists) {
          window.Module.FS.mkdirTree(gameDataPath);
        }

        const gameDataRoot = window.Module.FS.readdir(gameDataPath);
        for (const preload of game.config.preload) {
          if (!gameDataRoot.includes(preload)) {
            // can't rename a file to different FS, so copy instead
            const fileContents = window.Module.FS.readFile(`/tmp/${preload}`);
            window.Module.FS.writeFile(
              `${gameDataPath}${preload}`,
              fileContents
            );
          }
        }

        console.log("Persistent file system mounted");

        window.callMain(paramArgs);
      });
    },
    noInitialRun: true,
    preRun: () => {
      window.Module.FS.mkdir("/gamedata");
      window.Module.FS.mount(IDBFS, {}, "/gamedata"); // eslint-disable-line
      const { preload } = game.config;
      for (const file of preload) {
        window.Module.FS.createPreloadedFile("tmp", file, file, true, true);
      }
    },
    printErr: function (text) {
      if (arguments.length > 1)
        text = Array.prototype.slice.call(arguments).join(" ");
      console.error(text);
    },
    canvas: (function () {
      var canvas = document.getElementById("canvas") as HTMLCanvasElement;
      canvas.addEventListener(
        "webglcontextlost",
        function (e) {
          alert("WebGL context lost. You will need to reload the page.");
          e.preventDefault();
        },
        false
      );
      return canvas;
    })(),
    print: function (text) {
      console.log(text);
    },
    setStatus: function (text) {
      console.log(text);
    },
    totalDependencies: 0,
    monitorRunDependencies: function (left) {
      this.totalDependencies = Math.max(this.totalDependencies!, left);
      window.Module.setStatus(
        left
          ? "Preparing... (" +
              (this.totalDependencies - left) +
              "/" +
              this.totalDependencies +
              ")"
          : "All downloads complete."
      );
    },
  } as EmscriptenModule;

  const wasmScript = document.createElement("script");
  wasmScript.src = game.engine.script;
  wasmScript.async = true;
  document.body.appendChild(wasmScript);
  return () => {
    document.body.removeChild(wasmScript);
  };
}

interface GameViewProps {
  gameId: string;
}

export default function GameView({ gameId }: GameViewProps) {
  const game = useGames(gameId);

  console.log(game);

  useEffect(() => mountEngine(game), [game]);

  return (
    <div className="GameView_container">
      <canvas
        id="canvas"
        className="frame"
        onContextMenu={(e) => e.preventDefault()}
        tabIndex={-1}
      />
    </div>
  );
}
