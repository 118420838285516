import { useState } from "react";
import { useParams } from "react-router-dom";
import Drawer from "@material-ui/core/Drawer";
import TextField from "@material-ui/core/TextField";
import GameView from "../../components/GameView";
import FileBrowser from "../../components/FileBrowser";
import useGames from "../../hooks/useGames";
import ResetIcon from "./reset.svg";
import FolderIcon from "./file.svg";
import InfoIcon from "./info.svg";
import SettingsIcon from "./settings.svg";
import "./Play.css";

function SettingsApp() {
  return (
    <div className="SettingsApp">
      <TextField id="standard-basic" label="Standard" />
      <TextField id="standard-basic" label="Standard" />
    </div>
  );
}

export default function Play() {
  const { id } = useParams();
  const game = useGames(id);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [activeApp, setActiveApp] = useState();

  function openApp(component) {
    setActiveApp(component);
    setSidebarOpen(true);
  }

  return (
    <div>
      <Drawer
        className="Play_sidebar"
        anchor="left"
        variant="persistent"
        open={sidebarOpen}
        onClose={() => setSidebarOpen(false)}
      >
        {activeApp}
      </Drawer>
      <div
        className={`Play_container ${
          sidebarOpen ? "Play_container__shifted" : ""
        }`}
        onClick={() => (sidebarOpen ? setSidebarOpen(false) : {})}
      >
        <div className="Play_section Play_header">header</div>
        <div className="Play_section Play_body">
          <div className="FunctionBar">
            <img
              className="FunctionBar_button"
              src={InfoIcon}
              alt="Game Info"
            />
            <img
              className="FunctionBar_button"
              src={FolderIcon}
              alt="Manage Game Files"
              onClick={() => openApp(<FileBrowser game={game} />)}
            />
            <img
              className="FunctionBar_button"
              src={SettingsIcon}
              alt="Settings"
              onClick={() => openApp(<SettingsApp />)}
            />
            <img
              className="FunctionBar_button"
              src={ResetIcon}
              alt="Reset Game"
            />
          </div>
          <GameView gameId={id} />
        </div>
        <div className="Play_section Play_footer">footer</div>
      </div>
    </div>
  );
}
